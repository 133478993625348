import { EditorState } from '@codemirror/state';
import { type EditorView } from '@codemirror/view';
import { Button, ConfirmationModal } from 'components/ui/atomic-components';
import { useResetTemplateCache } from 'data/templates/hooks/use-reset-template-cache';
import { useRef, type ReactElement } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { useTemplateActions, useTemplateCode } from 'store/templates';
import styled from 'styled-components';
import { FontXs } from 'styles/typography';
import { CodeEditor } from './code-editor';

const StyledConfirmationModal = styled(ConfirmationModal)`
  .ant-modal-content {
    width: 100%;
  }

  .ant-modal-body {
    padding: ${({ theme }) => `${theme.spacing[32]}  ${theme.spacing[16]}`};
    display: flex;
    align-items: center;
    flex-direction: column;
  }
`;

const Wrapper = styled.div`
  width: 548px;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing[12]};
`;

const Subheader = styled.div`
  ${FontXs};

  color: ${({ theme }) => theme.colors.textLabel};
`;

interface Props {
  open?: boolean;
  closeModal?: () => void;
}

export const InputJSONModal = ({ open, closeModal }: Props): ReactElement => {
  const intl = useIntl();

  const editorViewRef = useRef<EditorView | null>(null);
  const setEditorView = (editorView: EditorView | null) => (editorViewRef.current = editorView);
  const { setCode } = useTemplateActions();
  const code = useTemplateCode();
  const { templateName } = useParams();

  const resetTemplateCache = useResetTemplateCache();

  const handleSave = () => {
    setCode(editorViewRef.current?.state.doc.toString() ?? '');
    closeModal?.();
  };

  const handleResetCache = () => {
    if (!templateName) {
      return;
    }

    resetTemplateCache.mutate(
      { templateName },
      {
        onSuccess: (data) => {
          const view = editorViewRef.current;

          if (!view) {
            return;
          }

          const newState = EditorState.create({
            doc: JSON.stringify(data.params, undefined, 4),
          });

          view.setState(newState);
        },
      },
    );
  };

  return (
    <>
      <StyledConfirmationModal
        destroyOnClose
        footerExtra={
          <Button disabled={resetTemplateCache.isPending} size="small" onClick={handleResetCache}>
            {'Reset params'}
          </Button>
        }
        okText={<FormattedMessage id="Save" />}
        open={open}
        title={<FormattedMessage id="model.templates.content.header.use_template" />}
        width={'848px'}
        onCancel={closeModal}
        onOk={handleSave}
      >
        <Wrapper>
          <Subheader>
            <FormattedMessage id="model.templates.content.use_template.subheader.input_modal" />
          </Subheader>

          <CodeEditor
            placeholderText={intl.formatMessage({
              id: 'model.templates.content.use_template.modal.code_placeholder',
            })}
            setEditorView={setEditorView}
            value={code}
          />
        </Wrapper>
      </StyledConfirmationModal>
    </>
  );
};
