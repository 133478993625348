import axios from 'axios';
import { type ModelTemplate } from './types';

const BASE_URL = 'v1/templates';

export const TemplatesApi = {
  getAll: async (): Promise<ModelTemplate[]> => {
    return axios.get(BASE_URL).then(({ data }) => {
      return data;
    });
  },

  find: async (name: string): Promise<ModelTemplate> => {
    return axios.get(`${BASE_URL}/${name}`).then(({ data }) => {
      return data;
    });
  },

  create: async (name: string, body: ModelTemplate): Promise<void> => {
    return axios.post(`${BASE_URL}/${name}/create`, body).then(({ data }) => {
      return data;
    });
  },

  apply: async (name: string, body: ModelTemplate): Promise<void> => {
    return axios.post(`${BASE_URL}/${name}/apply`, body).then(({ data }) => {
      return data;
    });
  },

  reset: async (name: string): Promise<void> => {
    return axios.patch(`${BASE_URL}/${name}/reset`).then(({ data }) => {
      return data;
    });
  },

  resetCache: async (name: string): Promise<ModelTemplate> => {
    return axios.post(`${BASE_URL}/${name}/reset-cache`).then(({ data }) => {
      return data;
    });
  },

  resetAllCache: async (): Promise<ModelTemplate[]> => {
    return axios.post(`${BASE_URL}/reset-cache`).then(({ data }) => {
      return data;
    });
  },
};
